<template>
  <div>
    <div class="row">
      <div class="col">
        <h3>Factura <b class="text-primary">#{{ last_consecutive_invoice }}</b></h3>
        <h3>Total a facturar: {{ formatCurrency(total) }}</h3>
      </div>
      <div class="col text-right">
        <h4>
          {{ date }} <br>
          {{ time }}
        </h4>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <validation-observer ref="formValidation">
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <div class="row">

              <div class="col">
                <b-form-group label="Cliente">
                  <b-form-input :value="client.full_name" disabled />
                </b-form-group>
              </div>

              <div class="col">
                <b-form-group label="Método de pago *" label-for="payment_method">
                  <validation-provider
                    #default="{ errors }"
                    name="Método de pago"
                    rules="required"
                  >
                    <small class="text-danger">{{ errors[0] }}</small>
                    <v-select
                      id="payment_method"
                      v-model="invoice.payment_method"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="
                        $store.state.app.payment_methods.filter(
                          i => i.hacienda === true
                        )
                      "
                      :reduce="payment_method => payment_method.id"
                      :clearable="false"
                      class="per-page-selector"
                      label="name"
                      name="Método de pago"
                    />
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group label="Medio de pago *" label-for="payment_form">
                  <validation-provider
                    #default="{ errors }"
                    name="Medio de pago"
                    rules="required"
                  >
                    <small class="text-danger">{{ errors[0] }}</small>
                    <v-select
                      id="payment_form"
                      v-model="invoice.payment_form"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="$store.state.app.payment_forms"
                      :reduce="payment_form => payment_form.id"
                      :clearable="false"
                      class="per-page-selector"
                      label="name"
                      name="Medio de pago"
                    />
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group label="Moneda *" label-for="currency">
                  <validation-provider
                    #default="{ errors }"
                    name="Moneda"
                    rules="required"
                  >
                    <small class="text-danger">{{ errors[0] }}</small>
                    <v-select
                      id="currency"
                      v-model="invoice.currency"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="$store.state.app.currencies"
                      :reduce="currency => currency.id"
                      :clearable="false"
                      class="per-page-selector"
                      label="name"
                      name="Moneda"
                      disabled
                    />
                  </validation-provider>
                </b-form-group>
              </div>

            </div>

            <div class="row mt-5">
              <div class="col">
                <b-card no-body class="card-company-table">
                  <b-table
                    :items="services"
                    responsive
                    :fields="serviceColumns"
                    class="mb-0"
                  >
                    <template #cell(price)="data">
                      {{ formatCurrency(subtotal) }}
                    </template>

                    <template #cell(iva)="data">
                      {{ data.item.tax * 100 }}%
                    </template>

                    <template #cell(total)="data">
                      {{ formatCurrency(total) }}
                    </template>

                  </b-table>
                </b-card>
              </div>
            </div>

            <div class="row justify-content-end">
              <div class="col">
                <div class="col-lg-9 col-md-9 col-sm-12 col-12">
                  <b-form-group label="Notas" label-for="notes">
                    <b-form-textarea
                      v-model="invoice.note"
                      id="notes"
                      placeholder="Notas"
                      rows="2"
                      max-rows="5"
                    />
                  </b-form-group>

                  <b-form-group label="Terminos y condiciones" label-for="terms">
                    <b-form-textarea
                      v-model="invoice.terms"
                      id="terms"
                      placeholder="Terminos y condiciones"
                      rows="2"
                      max-rows="5"
                    />
                  </b-form-group>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-12 col-12">
                <b-list-group flush>
                  <b-list-group-item>
                    <b>Subtotal:</b> {{ formatCurrency(subtotal) }}
                  </b-list-group-item>
                  <b-list-group-item>
                    <b>Total: </b> {{ formatCurrency(total) }}
                  </b-list-group-item>
                </b-list-group>
              </div>
            </div>

            <hr />

            <div class="text-center">
              <b-overlay
                :show="overlay_submit"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
              >
                <b-button
                  type="submit"
                  variant="gradient-primary"
                  block
                  @click="storeInvoice()"
                >
                  <feather-icon icon="SaveIcon" class="mr-50" />
                  <span class="align-middle">Guardar</span>
                </b-button>
              </b-overlay>
            </div>

          </b-form>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormCheckbox,
  BOverlay,
  BFormTextarea,
  BTable,
  BCard,
  BListGroup,
  BListGroupItem
} from "bootstrap-vue";
import vSelect from "vue-select";
import { currencyFormat } from "@/helpers";

import InvoiceService from "@/services/invoices.service";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Swal from "sweetalert2";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  props: ['services', 'last_consecutive_invoice', 'client', 'selectedOrders', 'subtotal', 'total'],
  components: {
    BListGroup,
    BListGroupItem,
    vSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BFormCheckbox,
    BOverlay,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BTable,
    BCard
  },
  data() {
    return {
      date: '',
      time: '',
      clock: null,

      required,
      overlay_submit: false,
      serviceColumns: [
        { key: "cabys_code", label: "Código cabys" },
        { key: "name", label: "Servicio" },
        { key: "price", label: "Precio" },
        { key: "iva", label: "Iva (%)" },
        { key: "total", label: "Total" }
      ],

      invoice: {
        currency: { name: 'Colones', value: 1 },
        payment_method: '',
        payment_form: '',
        terms: '',
        note: ''
      },

      week: ['LUNES', 'MARTES', 'MIERCOLES', 'JUEVES', 'VIERNES', 'SABADO', 'DOMINGO'],
    }
  },
  methods: {
    storeInvoice() {
      this.$refs.formValidation.validate().then(async success => {
        if (success) {
          try {
            this.overlay_submit = true;
            const dataInvoice = {
              orders: this.selectedOrders,
              payment_method: this.invoice.payment_method,
              payment_form: this.invoice.payment_form,
              note: this.invoice.note,
              terms: this.invoice.terms,
              services: this.services,
              client: this.client,
              subtotal: this.subtotal,
              total: this.total
            };
            const { data: res } = await InvoiceService.convertOrdersToInvoice(
              dataInvoice
            );
            if (res.success) {
              this.invoice = {...this.$options.data().invoice}
              this.$emit('invoiceCreated')
              this.toast(res.message);
            } else {
              this.swal(res.message);
            }
            this.overlay_submit = false;
          } catch (error) {
            console.log(error)
            this.swal("Ha ocurrido un error al registrar la factura");
            this.overlay_submit = false;
          }
        }
      });
    },
    startClock() {
      this.clock = setInterval(() => {
        let cd = new Date();
        this.time = this.zeroPadding(cd.getHours(), 2) + ':' + this.zeroPadding(cd.getMinutes(), 2) + ':' + this.zeroPadding(cd.getSeconds(), 2)
        this.date = this.week[cd.getDay()] + ' ' + this.zeroPadding(cd.getDate(), 2) + '-' + this.zeroPadding(cd.getMonth()+1, 2) + '-' + this.zeroPadding(cd.getFullYear(), 4)
      }, 1000)
    },
    zeroPadding(num, digit) {
      let zero = '';
      for(var i = 0; i < digit; i++) {
        zero += '0';
      }
      return (zero + num).slice(-digit);
    },
    formatCurrency(amount) {
      return currencyFormat(amount);
    },
    toast(title, variant = "success", icon = "CheckCircleIcon") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          variant: variant
        }
      });
    },
    swal(title, content = "") {
      Swal.fire({
        title: title,
        text: content,
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary"
        },
        buttonsStyling: false
      });
    }
  },
  created() {
    this.startClock()
  },
  beforeDestroy() {
    clearInterval(this.clock)
  }
}
</script>
