<template>
  <b-overlay :show="loading" rounded="sm">
    <b-card title="Estado de cuenta">

      <div class="row mb-1">
        <div class="col-9 d-flex align-items-center">
          <div>
            <label for="">Cliente *</label>
            <v-select
              v-model="client"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="$store.state.app.clients"
              :clearable="false"
              class="per-page-selector mr-1"
              label="full_name"
              style="width: 410px"
              placeholder="Seleccione un cliente"
            />
          </div>
          <div>
            <label for="">Rango de fechas *</label> <br>
            <date-picker
              range-separator="-"
              v-model="rangePicker"
              :default-value="rangePicker"
              range
              append-to-body
              lang="es"
              format="YYYY-MM-DD"
              style="width: 200px"
              class="mr-1"
            ></date-picker>
          </div>
          <div class="mr-1">
            <b-button
              :disabled="!client"
              variant="success"
              class="btn-icon mt-2"
              @click="getReport()"
            >
              <feather-icon icon="SearchIcon" size="16"/>
            </b-button>
          </div>

          <div v-if="report" class="mr-1 mt-2">
            <b-button
              @click="exportAccountState('pdf')"
              variant="gradient-danger"
            >
              <feather-icon icon="FileTextIcon" size="16" />
              PDF
            </b-button>
          </div>
          <div v-if="report" class="mr-1 mt-2">
            <b-button
              @click="exportAccountState('excel')"
              variant="gradient-success"
            >
              <feather-icon icon="FileTextIcon" size="16" />
              Excel
            </b-button>
          </div>

          <div v-if="report" class="mt-2">
            <b-button
              @click="viewOrders()"
              variant="gradient-info"
            >
              <feather-icon icon="FileTextIcon" size="16" />
              Facturar
            </b-button>
          </div>

        </div>
        <div class="col-3 text-right" v-if="report">
          <h4 class="text-danger">
            Total mensajería: {{ formatCurrency(report.total_services) }}
          </h4>
          <h4 class="text-danger">
            Total compras: {{ formatCurrency(report.total_addons) }}
          </h4>
          <h4 class="text-danger">
            Total deuda: {{ formatCurrency(report.total_addons + report.total_services) }}
          </h4>
        </div>
      </div>


      <div v-if="report" class="d-flex justify-content-between">
        <h3>Estado de cuenta de {{ client.full_name }}</h3>
      </div>


      <div v-if="report" class="row">
        <div class="col-lg-10 col-md-10 col-xs-12">

          <b-card
            :title="`Total en mensajería: ${formatCurrency(report.total_services)}`"
          >
            <b-card-text>
              <table class="table">
                <thead>
                  <tr>
                    <th>Código de orden</th>
                    <th>Fecha de orden</th>
                    <th>Servicio</th>
                    <th>Descripción</th>
                    <th>Total</th>
                    <th>Deuda</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) of report.services" :key="i">
                    <td>
                      <router-link
                        v-b-tooltip.hover.top="`${item.order.description}`"
                        :to="{ name: 'order-detail', params: { id: item.order.id } }"
                      >
                        #{{ item.order.code }}
                      </router-link>
                    </td>
                    <td>
                      {{ dateFormat(item.order_date) }}
                    </td>
                    <td>{{ item.service.service.name }}</td>
                    <td>{{ item.service.service.description || 'S/D' }}</td>
                    <td>{{ formatCurrency(item.service.price) }}</td>
                    <td>{{ formatCurrency( item.order.total - item.order.payment_services) }}</td>
                  </tr>
                </tbody>
              </table>
            </b-card-text>
          </b-card>

          <b-card
            :title="`Total en compras: ${formatCurrency(report.total_addons)}`"
          >
            <b-card-text>
              <table class="table">
                <thead>
                  <tr>
                    <th>Código de orden</th>
                    <th>Fecha de orden</th>
                    <th>Nombre</th>
                    <th>Total</th>
                    <th>Deuda</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) of report.addons" :key="i">
                    <td>
                      <router-link
                        v-b-tooltip.hover.top="`${item.order.description}`"
                        :to="{ name: 'order-detail', params: { id: item.order.id } }"
                      >
                        #{{ item.order.code }}
                      </router-link>
                    </td>
                    <td>
                      {{ dateFormat(item.order_date) }}
                    </td>
                    <td>{{ item.addon.name }}</td>
                    <td>{{ formatCurrency(item.addon.price) }}</td>
                    <td>{{ formatCurrency(item.order.additional_total - item.order.payment_additionals) }}</td>
                  </tr>
                </tbody>
              </table>
            </b-card-text>
          </b-card>

        </div>
      </div>

    </b-card>

    <b-modal
      v-model="openModalOrders"
      no-close-on-backdrop
      cancel-variant="secondary"
      hide-footer
      centered
      size="xl"
      title="Seleccionar ordenes para convertir en factura"
    >
      <b-card
        :title="`${report.orders.length} Ordenes`"
        v-if="report"
      >
        <div v-if="!convert_invoices">
          <b-button
            v-if="selectedOrders.length"
            @click="convertInvoice()"
            variant="gradient-primary"
            class="mb-1"
          >
            <feather-icon icon="FileTextIcon" size="16" />
            Generar factura
          </b-button>

          <b-card-text>
            <vue-perfect-scrollbar
              v-if="report.orders.length"
              ref="refChatLogPS"
              :settings="perfectScrollbarSettings"
              class="user-chats scroll-area"
            >
              <div style="height: 500px">
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                        <b-form-checkbox
                          v-model="select_all_orders"
                          class="custom-control-primary"
                        />
                      </th>
                      <th>Fecha</th>
                      <th>Código de orden</th>
                      <th>Total</th>
                      <th>Compras</th>
                      <th>Deuda</th>
                      <th>Pagado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) of report.orders" :key="i">
                      <td style="width: 50px">
                        <b-form-checkbox
                          v-model="selectedOrders"
                          :value="{id: item.id, subtotal: item.subtotal, total: item.total}"
                          class="custom-control-primary"
                        />
                      </td>
                      <td style="width: 200px">
                        <template v-if="item.scheduled">
                          <span
                            class="font-weight-bold d-block text-nowrap"
                            style="position:relative"
                          >
                            <i
                              class="fa fa-clock text-warning"
                              style="position:absolute;left:-17px;top:3px"
                            ></i>
                            {{ dateFormat(item.scheduled_date, "DD-MM-YYYY") }}
                          </span>
                          <small class="text-muted">
                            {{ dateFormat(item.scheduled_date, "hh:mm A") }}
                          </small>
                        </template>
                        <template v-else>
                          <span class="font-weight-bold d-block text-nowrap">
                            {{ dateFormat(item.created_at, "DD-MM-YYYY") }}
                          </span>
                          <small class="text-muted">
                            {{ dateFormat(item.created_at, "hh:mm A") }}
                          </small>
                        </template>
                      </td>
                      <td style="width: 200px">
                        <router-link
                          v-b-tooltip.hover.top="`${item.description}`"
                          :to="{ name: 'order-detail', params: { id: item.id } }"
                        >
                          #{{ item.code }}
                        </router-link>
                      </td>
                      <td>{{ formatCurrency(item.total) }}</td>
                      <td>{{ formatCurrency(item.additional_total) }}</td>
                      <td>{{ formatCurrency(item.due.due_total) }}</td>
                      <td>{{ formatCurrency(item.total_paid) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </vue-perfect-scrollbar>
          </b-card-text>
        </div>
        <div v-else>
          <b-overlay :show="loading_form_invoice" rounded="sm">
            <b-card title="Generar factura electronica">
              <b-card-text>
                <b-button
                  @click="cancelInvoice()"
                  variant="gradient-danger"
                  class="mb-1"
                >
                  <feather-icon icon="ArrowLeftIcon" size="16" />
                  Cancelar
                </b-button>
                <form-orders-to-invoice
                  :services="service_cabys_general"
                  :last_consecutive_invoice="last_consecutive_invoice"
                  :client="client"
                  :selectedOrders="selectedOrders"
                  :total="amountTotalInvoice"
                  :subtotal="amountSubtotalInvoice"
                  @invoiceCreated="invoiceCreated"
                />
              </b-card-text>
            </b-card>
          </b-overlay>
        </div>

      </b-card>
    </b-modal>

  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BCardText,
  BButton,
  BTable,
  BBadge,
  BFormCheckbox,
  BListGroup,
  BListGroupItem,
  VBTooltip,
  BModal
} from 'bootstrap-vue'

import ReportService from '@/services/report.service'
import DatePicker from 'vue2-datepicker';
import vSelect from 'vue-select'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import FormOrdersToInvoice from '@/components/FormOrdersToInvoice'
import Services from '@/services/services.service'

import {
  currencyFormat,
  downloadBlob
} from '@/helpers'
import Swal from 'sweetalert2'
import moment from "moment";
moment.locale("es");

export default {
  components: {
    FormOrdersToInvoice,
    BModal,
    BCardText,
    vSelect,
    VuePerfectScrollbar,
    BOverlay,
    BCard,
    DatePicker,
    BButton,
    BTable,
    BBadge,
    BFormCheckbox,
    BListGroup,
    BListGroupItem
  },
  data() {
    return {
      select_all_orders: false,
      loading_form_invoice: false,
      convert_invoices: false,
      perfectScrollbarSettings: {
        maxScrollbarLength: 250,
      },
      selectedOrders: [],
      client: '',
      perfectScrollbarSettings: {
        maxScrollbarLength: 10,
      },
      rangePicker: [
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
				new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      ],
      loading: false,
      report: null,
      openModalOrders: false,
      service_cabys_general: null,
      last_consecutive_invoice: '',

      amountTotalInvoice: 0,
      amountSubtotalInvoice: 0
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  watch: {
    openModalOrders(val) {
      if (!val) {
        this.selectedOrders = []
      }
    },
    select_all_orders(newval) {
      if (newval) {
        this.selectedOrders = this.report.orders.map(item => {
          return { id: item.id, subtotal: item.subtotal, total: item.total}
        })
      } else {
        this.selectedOrders = []
        this.amountTotalInvoice = 0
        this.amountSubtotalInvoice = 0
        this.convert_invoices = false
      }
    }
  },
  methods: {
    cancelInvoice() {
      this.convert_invoices = false
      this.amountTotalInvoice = 0
      this.amountSubtotalInvoice = 0
      this.select_all_orders = false
    },
    async convertInvoice() {
      this.convert_invoices = true
      try {
        this.calculateAmountTotalForInvoice()
        this.loading_form_invoice = true
        const { data:res } = await Services.getServiceCabysGeneral()
        this.service_cabys_general = [res.data.service]
        this.last_consecutive_invoice = res.data.last_consecutive_invoice
      } catch (error) {
        console.log(error)
      }
      this.loading_form_invoice = false
    },
    invoiceCreated() {
      this.selectedOrders = []
      this.amountTotalInvoice = 0
      this.amountSubtotalInvoice = 0
      this.convert_invoices = false
      this.select_all_orders = false
      this.getReport()
    },
    calculateAmountTotalForInvoice() {
      this.selectedOrders.forEach(i => {
        this.amountSubtotalInvoice += parseFloat(i.subtotal)
        this.amountTotalInvoice += parseFloat(i.total)
      })
    },
    viewOrders() {
      this.openModalOrders = true
    },
    async exportAccountState(export_type) {
      if (this.rangePicker.length == 2) {
        try {
          this.loading = true
          const { data:res } = await ReportService.getAccountState(
            this.client.id,
            this.rangePicker,
            'blob',
            export_type
          )
          this.loading = false
          let ext
          if (export_type == 'pdf') ext = 'pdf'
          if (export_type == 'excel') ext = 'xlsx'
          return downloadBlob(res, `Estado de cuenta ${this.client.full_name}.${ext}`);
        } catch (error) {
          this.loading = false
          Swal.fire({
            title: 'Ha ocurrido un error al generar el pdf del estado de cuenta',
            text: '',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      }
    },
    async getReport() {
      if (this.rangePicker.length == 2) {
        this.selectedOrders = []
        this.loading = true
        const { data:res } = await ReportService.getAccountState(
          this.client.id,
          this.rangePicker,
          'json'
        )
        if (res.success) {
          this.report = res.data
        } else {
          Swal.fire({
            title: res.message,
            text: '',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        this.loading = false
      }
    },
    formatCurrency(amount) {
      return currencyFormat(amount)
    },
    dateFormat(date, format = "DD/MM/YYYY") {
      return moment(date).format(format);
    },
  }
}
</script>
